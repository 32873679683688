import React, { FC } from "react";

interface IProps {
  onClick: () => void
}


const CloseButtonMobile: FC<IProps> = ({ onClick }) => {
  return (
    <svg style={{position: 'fixed', right: '20px', bottom: '20px'}} onClick={onClick} width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1846_29580)">
        <path
          d="M68 41C68 26.0883 55.9117 14 41 14C26.0883 14 14 26.0883 14 41C14 55.9117 26.0883 68 41 68C55.9117 68 68 55.9117 68 41Z"
          fill="white"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M41.2029 43.3245L47.1421 49.2637L49.2635 47.1424L43.3242 41.2032L49.4056 35.1218L47.2843 33.0005L41.2029 39.0818L35.1213 33.0002L33 35.1216L39.0816 41.2032L33.1421 47.1426L35.2635 49.2639L41.2029 43.3245Z"
            fill="#091E42"/>
      <defs>
        <filter id="filter0_d_1846_29580" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1846_29580"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1846_29580" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
}

export default CloseButtonMobile