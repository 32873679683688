import React, { FC } from "react";
import "./HikingModal.scss";
import CloseButton from "../CloseButton/CloseButton";
import { IPath } from "../../types/path";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

interface IProps {
  path: IPath;
  onClose: () => void;
}

const HikingModal: FC<IProps> = ({ onClose, path }) => {

  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
        }
      },
    ]
  };
  return (
    <div className="hiking-modal">
      <h2>{path.name} <CloseButton onClick={onClose}/></h2>
      <h5>{t('Пішохідний маршрут')}</h5>

      {path.images && path.images.length ? <div className="hiking-modal-carousel">
        <Slider {...settings}>
          {
            path.images.map((item) => {
              return (
                <div>
                  <div className="hiking-modal-carousel__item">
                    <img src={item.url} height={160}/>
                  </div>
                </div>

              )
            })
          }

        </Slider>
      </div> : null}

      {(path.length || path.duration) ? <div className="hiking-modal-info">
        <div className="hiking-modal-info__title">{t('Протяжність')}</div>
        <div className="hiking-modal-info__value">
          {path.length ? <span>{path.length} {t('км')}</span> : null}
          {path.duration && path.length ? <span>,</span> : null}{path.duration ? <span> {t('час переходу')} - {path.duration} {t('год')}</span> : null}
        </div>
      </div>: null}
      <div className="hiking-modal-info">
        <div className="hiking-modal-info__title">{t('Рівень складності')}</div>
        <div className="hiking-modal-info__value">{t(path.difficulty)}</div>
      </div>

      {
        path?.button?.url &&   <a href={path.button.url} target="_blank" className="hiking-button">{path.button.title}</a>
      }


    </div>
  )
}

export default HikingModal;