import React, { FC } from "react";

interface IProps{
  onClick: () => void
}

const CloseButton: FC<IProps> = ({onClick}) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer',width: 56, height:56, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: `-13px`, marginTop: `-13px`, marginBottom: `-13px`}}>
      <svg  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5.5L5 15.5M5 5.5L15 15.5" stroke="#ADB5C0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  )
}

export default CloseButton;