import React from "react";
import "./BurgerButton.scss";

const BurgerButton = () => {
  return (
    <svg className="burger-button" width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1903_28665)">
        <path d="M68 41C68 26.0883 55.9117 14 41 14C26.0883 14 14 26.0883 14 41C14 55.9117 26.0883 68 41 68C55.9117 68 68 55.9117 68 41Z" fill="#3E9A36"/>
      </g>
      <path d="M51 49H31V46.333H51V49ZM51 42.335H31V39.666H51V42.334V42.335ZM51 35.667H31V33H51V35.666V35.667Z" fill="white"/>
      <defs>
        <filter id="filter0_d_1903_28665" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.502 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1903_28665"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1903_28665" result="shape"/>
        </filter>
      </defs>
    </svg>


  )
}

export default BurgerButton