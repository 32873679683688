import React, { FC, useState } from "react";
import AccordionIcon from "../icons/AccordionIcon";
import "./NavAccordion.scss";
import { IPath } from "../../../../types/path";


interface IProps {
  title: string;
  items: Array<IPath | {
    name: string
    icon: any;
  }>
  onMenuClick?: (item: IPath) => void;
  isOpenInitial?: boolean
  currentPath?: IPath | null;
}

const NavAccordion: FC<IProps> = ({ currentPath, title, items, onMenuClick,isOpenInitial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenInitial || false);

  return (
    <div className="nav-items-item">
      <h4 className="nav-items-item__title" onClick={() => setIsOpen(!isOpen)}>{title} <AccordionIcon isOpen={isOpen}/></h4>

      {isOpen && <ul className="nav-submenu">
        {
          items.map((item) => {
            return (
              // @ts-ignore
              <li className={`nav-submenu__item ${currentPath?.alias && item.alias === currentPath?.alias ? 'nav-submenu__item--active' : ''}`} onClick={() => onMenuClick && onMenuClick(item)}>
                {item.icon}
                {item.name}
              </li>
            )
          })
        }
      </ul>}
    </div>
  )
}

export default NavAccordion;