

export const pathColors = {
  'park': 'rgba(21, 158, 209, 1)',
  'ia-pidu-v-daleki-gori': 'rgba(210, 33, 61, 1)',
  'flower': 'rgba(94, 55, 158, 1)',
  'panorama': 'rgba(250, 210, 3, 1)',
  'view': 'rgba(154, 93, 20, 1)',
  'karpatske-zoloto': 'rgba(66, 161, 71, 1)',
  'stone': 'rgba(66, 161, 71, 1)',
  'stone-2': 'rgba(210, 33, 61, 1)',
  'stone-3': 'rgba(66, 161, 71, 1)',

}