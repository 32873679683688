import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "Пішохідний маршрут": "Walking route",
          "Протяжність":"Length",
          "км":"km",
          "год":"hour",
          "час переходу":"Walking duration",
          "Рівень складності":"Difficulty Level",
          "Умовні позначення":"Conventional Notations",
          "Легкий рівень":"Easy Level",
          "Середній рівень":"Middle level",
          "Складний рівень":"Difficult Level",
          "Інфраструктура":"Infrastructure",
          "Вершини гір":"Mountain tops",
          "Витяги":"Lifts",
          "Полонини":"Mountain plains",
          "Автомобільні дороги":"Roads",
          "Річки":"Rivers",
        }
      },
      uk: {
        translation: {
          "Пішохідний маршрут": "Пішохідний маршрут",
          "Протяжність":"Протяжність",
          "км":"км",
          "год":"год",
          "час переходу":"час переходу",
          "Рівень складності":"Рівень складності",
          "Умовні позначення":"Умовні позначення",
          "Легкий рівень":"Легкий рівень",
          "Середній рівень":"Середній рівень",
          "Складний рівень":"Складний рівень",
          "Інфраструктура":"Інфраструктура",
          "Вершини гір":"Вершини гір",
          "Витяги":"Витяги",
          "Полонини":"Полонини",
          "Автомобільні дороги":"Автомобільні дороги",
          "Річки":"Річки",
          "easy":"Легкий",
          "medium":"Середній",
          "hard":"Складний",
        }
      }
    },
    lng: "uk", // if you're using a language detector, do not define the lng option
    fallbackLng: "uk",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
