import React from "react";

const IconMount = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#ADB5C0"/>
      <path d="M12 5L5 17H19L12 5Z" fill="white"/>
    </svg>
  )
}
export default IconMount;