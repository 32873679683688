import React, { FC } from "react";


interface IProps{
  onClick: () => void;
}

const DefaultScreenButton: FC<IProps> = ({onClick}) => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <g filter="url(#filter0_d_1846_27602)">
        <path d="M68 41C68 26.0883 55.9117 14 41 14C26.0883 14 14 26.0883 14 41C14 55.9117 26.0883 68 41 68C55.9117 68 68 55.9117 68 41Z" fill="white"/>
      </g>
      <g clipPath="url(#clip0_1846_27602)">
        <path d="M38 33V36C38 37.1046 37.1046 38 36 38H33" stroke="#091E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44 49L44 46C44 44.8954 44.8954 44 46 44L49 44" stroke="#091E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49 38L46 38C44.8954 38 44 37.1046 44 36L44 33" stroke="#091E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33 44L36 44C37.1046 44 38 44.8954 38 46L38 49" stroke="#091E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="filter0_d_1846_27602" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.502 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1846_27602"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1846_27602" result="shape"/>
        </filter>
        <clipPath id="clip0_1846_27602">
          <rect width="24" height="24" fill="white" transform="translate(29 29)"/>
        </clipPath>
      </defs>
    </svg>


  )
}

export default DefaultScreenButton