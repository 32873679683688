import React from "react";
import './App.css';
import HikingMap from "./components/HikingMap/HikingMap";

const App = () => {
  return (
    <HikingMap/>
  );
};

export default App