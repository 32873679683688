import React, { FC } from "react";
import "./NavigationMenu.scss"
import CloseButton from "../CloseButton/CloseButton";
import NavAccordion from "../Menu/components/NavAccordion/NavAccordion";
import IconMount from "../Menu/components/icons/IconMount";
import LegendIcon from "../Menu/components/icons/LegendIcon";
import IconArrow from "../Menu/components/icons/IconArrow";
import IconAnimal from "../Menu/components/icons/IconAnimal";
import IconRoad from "../Menu/components/icons/IconRoad";
import IconRiver from "../Menu/components/icons/IconRiver";
import { IPath } from "../../types/path";
import { useTranslation } from "react-i18next";
import CloseButtonMobile from "../CloseButtonMobile/CloseButtonMobile";
import { useMediaQuery } from "react-responsive";
import { pathColors } from "../../config";

interface IProps {
  onClose: () => void
  onMenuClick: (item: IPath) => void
  paths: {
    easy: Array<IPath>
    medium: Array<IPath>
    hard: Array<IPath>
  };
  currentPath: IPath | null;
}



const NavigationMenu: FC<IProps> = ({ onClose, onMenuClick, paths, currentPath }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
  return (
    <div className="nav-menu">
      <div className="nav-menu__header">
        <h3>{t('Умовні позначення')}</h3>

        {
          isTabletOrMobile ?
            <CloseButtonMobile onClick={onClose}/>

            : <CloseButton onClick={onClose}/>
        }

      </div>
      <ul className="nav-items">
        {paths.easy.length ? <li>
          <NavAccordion
            currentPath={currentPath}
            isOpenInitial={true}
            onMenuClick={onMenuClick}
            title={t('Легкий рівень')}
            items={paths.easy.map((item: IPath) => {
              return {
                ...item,
                //@ts-ignore
                icon: <LegendIcon color={pathColors[item.alias]}/>
              }
            })}/>
        </li> : null
        }

        {paths.medium.length ?
          <li>
            <NavAccordion
              isOpenInitial={true}
              currentPath={currentPath}
              onMenuClick={onMenuClick}
              title={t('Середній рівень')}
              items={paths.medium.map((item: IPath) => {
                return {
                  ...item,
                  //@ts-ignore
                  icon: <LegendIcon color={pathColors[item.alias]}/>
                }
              })}/>
          </li>
          : null
        }


        {
          paths.hard.length ?
            <li>
              <NavAccordion
                isOpenInitial={true}
                currentPath={currentPath}
                onMenuClick={onMenuClick}
                title={t('Складний рівень')}
                items={paths.hard.map((item: IPath) => {
                  return {
                    ...item,
                    //@ts-ignore
                    icon: <LegendIcon color={pathColors[item.alias]}/>
                  }
                })}/>
            </li>
            :
            null
        }

        <li>
          <NavAccordion
            title={t('Інфраструктура')}
            items={[{
              name: t('Вершини гір'),
              icon: <IconMount/>,

            }, {
              name: t('Витяги'),
              icon: <IconArrow/>,

            }, {
              name: t('Полонини'),
              icon: <IconAnimal/>,

            }, {
              name: t('Автомобільні дороги'),
              icon: <IconRoad/>,

            }, {
              name: t('Річки'),
              icon: <IconRiver/>,
            }]}/>
        </li>
      </ul>
    </div>
  )
}

export default NavigationMenu