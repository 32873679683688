import React, { FC } from "react";

interface IProps{
  isOpen: boolean
}

const AccordionIcon: FC<IProps> = ({isOpen}) => {
  return (
    <div style={{lineHeight: 0,transformOrigin: 'center center', transform: `rotate(${isOpen ? '180deg' : 0})`}}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.97192 10.0115L11.9946 15.0115L17.0102 10.0132" stroke="#ADB5C0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>

  )
}

export default AccordionIcon