import React from "react";

const IconArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1835_49654)">
        <rect width="24" height="24" rx="12" fill="white"/>
        <path d="M-7.28781 33.3818C-7.46415 33.386 -7.63501 33.3207 -7.76357 33.2C-7.8309 33.1359 -7.88454 33.0589 -7.92116 32.9735C-7.95779 32.8881 -7.97668 32.796 -7.97668 32.7031C-7.97668 32.6102 -7.95779 32.5183 -7.92116 32.4329C-7.88454 32.3475 -7.8309 32.2704 -7.76357 32.2063L12.3742 10.1232C12.4393 10.0576 12.5167 10.0056 12.6019 9.97007C12.6872 9.93455 12.7787 9.91626 12.8711 9.91626C12.9635 9.91626 13.0549 9.93455 13.1401 9.97007C13.2254 10.0056 13.3028 10.0576 13.3679 10.1232C13.4334 10.1883 13.4855 10.2657 13.521 10.3509C13.5565 10.4362 13.5748 10.5277 13.5748 10.6201C13.5748 10.7124 13.5565 10.8039 13.521 10.8891C13.4855 10.9744 13.4334 11.0518 13.3679 11.1168L-6.77003 33.2139C-6.84074 33.2751 -6.92299 33.3215 -7.01192 33.3503C-7.10086 33.3792 -7.19467 33.3898 -7.28781 33.3818Z" fill="black"/>
        <path d="M17.9719 4.97266C16.3965 8.79271 15.4513 12.8431 15.1731 16.9658L12.2063 11.3681L6.3147 8.93308C10.397 8.27112 14.3439 6.94761 17.9999 5.01468" fill="black"/>
      </g>
      <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="white" strokeWidth="3"/>
      <defs>
        <clipPath id="clip0_1835_49654">
          <rect width="24" height="24" rx="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconArrow;