import React, { FC } from "react";

interface IProps{
  color: string;
}

const LegendIcon:FC<IProps> = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1910_1652)">
        <rect width="24" height="24" rx="12" fill="white"/>
        <rect x="-22.5" y="7.75" width="69" height="8.5" fill={color} stroke={color}/>
      </g>
      <defs>
        <clipPath id="clip0_1910_1652">
          <rect width="24" height="24" rx="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}
export default LegendIcon