import React, { FC } from "react";


interface IProps{
  onClick: () => void;
}

const FullScreenButton: FC<IProps> = ({onClick}) => {
  return (
    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <g filter="url(#filter0_d_1841_14909)">
        <path d="M68 41C68 26.0883 55.9117 14 41 14C26.0883 14 14 26.0883 14 41C14 55.9117 26.0883 68 41 68C55.9117 68 68 55.9117 68 41Z" fill="white"/>
      </g>
      <path d="M44 38L50 32M50 32H44M50 32V38M38 38L32 32M32 32L32 38M32 32L38 32M38 44L32 50M32 50H38M32 50L32 44M44 44L50 50M50 50V44M50 50H44" stroke="#091E42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
        <filter id="filter0_d_1841_14909" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.502 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1841_14909"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1841_14909" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
}

export default FullScreenButton